<template>
  <div class="Single-container">
    <NavBar :title="$t('single.lotteryBettingHistory')" :backColor="'#ff0000'" class="single-nav"></NavBar>
    <div class="singlepage">
      <div class="selectDown">
        <div class="selectData">
          <div class="selectType" @click="isshowType = true">
            <span class="text">{{ $t('recharge.selectItem') }}</span>
            <span class="type">{{ radio }}</span>
            <span class="squre"></span>
          </div>
          <div class="line"></div>
          <div class="selectTime" @click="isshowDate = true">
            <span class="text">{{ $t('lottery.time') }}</span>
            <span class="type">{{ selctDate || 'Date selector' }}</span>
            <span class="squre"></span>
          </div>
        </div>
        <div class="singleTitle">
          <span>{{ $t('single.time') }}</span>
          <span>{{ $t('single.betAmount') }}</span>
          <span>{{ $t('single.profit') }}</span>
          <span>{{ $t('single.status') }}</span>
        </div>
      </div>
      <div class="singlelist">
        <van-pull-refresh v-model="isrefreshLoding" @refresh="onRefresh" :success-text="refreshSuccessText"
          :loosing-text="$t('home.releaseToRefresh')" :loading-text="this.$t('common.loading')"
          :pulling-text="$t('common.dropDownRefresh')">
          <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad"
            :loading-text="$t('common.loading')">
            <van-cell v-for="(item, index) in lotteryList" :key="index" @click="
      $router.push({
        path: '/singleDetails',
        query: { singItem: JSON.stringify(item) },
      })
      " class="item-cell">
              <div slot="icon" class="fastIcon">
                <div slot="title" class="fastTitle">
                  <!-- <img class="record-item" :src="typeImage[item.typeCn]" /> -->
                  <span>{{ item.typeCn }}</span>
                </div>
                <div slot="label" class="fastLabel">
                  {{
      (item.endtime * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
    }}
                </div>
              </div>
              <div slot="title" class="title">
                <span slot="title">{{ item.money }}</span>
                <span slot="title" class="winning greenWinning" v-if="parseInt(item.winMoney) > 0">+{{ item.winMoney
                  }}</span>
                <span slot="title" class="winning" v-else>{{
      item.winMoney
    }}</span>
              </div>
              <div slot="default" class="status" :class="item.status === '待开奖' ? 'orange' : ''">
                {{ item.status === '已开奖' ? $t('single.settled') : $t('single.notOpenedYet') }}
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>

    <!-- 选择类型弹出框 -->
    <van-dialog v-model="isshowType" show-cancel-button :cancel-button-text="$t('common.cancel')"
      :confirm-button-text="$t('common.confirm')" confirmButtonColor="#00aa88" @confirm="getType">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell :title="item.title" clickable @click="radio = item.title" v-for="(item, index) in allType"
            :key="index">
            <template #right-icon>
              <van-radio :name="item.title" checked-color="#13cc88" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>
    <van-calendar v-model="isshowDate" :min-date="minDate" :title="$t('common.selectDate')"
      :confirm-text="$t('common.confirm')" @confirm="handleConfirm" />
  </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue'
import DateTiem from '@/components/DateTiem'
import { getLotteryDetail, getLotteryType } from '@/api/lottery.js'
import { unique } from '@/utils/unique.js'
// 图片
import Fast from '@/assets/images/user_order/Fast.png'
import Bcone from '@/assets/images/user_order/Bcone.png'
import Emerd from '@/assets/images/user_order/Emerd.png'
import Sapre from '@/assets/images/user_order/Sapre.png'
export default {
  name: 'UserSingle',
  components: {
    NavBar,
    DateTiem
  },
  data() {
    return {
      minDate: new Date('2021/10/01'),
      maxDate: new Date('2023/10/01'),
      loading: false,
      finished: false,
      isshowType: false,
      isrefreshLoding: false,
      radio: this.$route.query.title ? this.$route.query.title : this.$t('single.all'),
      type: this.$route.query.type ? this.$route.query.type : '',
      allType: [{ title: this.$t('single.all'), type: '' }],
      isshowDate: false,
      typeImage: { Fast: Fast, Bcone: Bcone, Emerd: Emerd, Sapre: Sapre },
      selctDate: '',
      pages: 1,
      total: 1, // 总开奖列表条数
      lotteryList: [], // 获取到的列表数
      finishedText: 'Đang tải...',
      refreshSuccessText: '', // 下拉的提示文字
      lotteryId: []
    }
  },
  async mounted() {
    //  获取lottery 类型
    const {
      data: { ret, msg, data }
    } = await getLotteryType({ lastinfo: 1 })
    ret === 1
      ? this.allType.push(
        ...data.map((item) => ({ title: item.title, type: item.type }))
      )
      : this.$toast(msg)
    window.localStorage.ALLTYPE = this.allType
  },
  created() {
    const date = new Date()
    const year = date.getFullYear()
    const month =
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    this.selctDate = `${year}-${month}-${day}`
    this.getLottery()
  },
  methods: {
    handleConfirm(e) {
      this.lotteryList = []
      this.selctDate = this.$options.filters.formatTime(e)
      this.isshowDate = false
      this.getLottery()
    },
    onLoad() {
      this.pages++
      this.getLottery()
    },

    // 获取选择的方式
    getType() {
      this.lotteryList = []
      this.getLottery()
    },

    // 获取选择到的时间
    sureEdit(date) {

      this.lotteryList = []
      this.selctDate = this.$options.filters.formatTime(date)

      this.getLottery()
    },

    // 获取开奖方式和状态
    async getLottery() {
      this.loading = true
      //   
      const {
        data: { ret, msg, data }
      } = await getLotteryDetail({
        type: this.type
          ? this.type
          : this.allType.find((item) => this.radio === item.title).type,
        day: this.selctDate ? this.selctDate : '',
        page: this.pages
      })
      try {
        ret === 1 ? this.lotteryList.push(...data.lists) : this.$toast(msg)
        // 
        this.loading = false
        // 4、 数据全部加载完成
        if (this.lotteryList.length === data.total) {
          this.finished = true
          this.finishedText = this.$t('common.noFurtherInformation')
        }
      } catch (error) {
        this.loading = false
      }
    },

    // 下拉刷新
    async onRefresh() {
      // 1、请求获取数据
      const {
        data: { data }
      } = await getLotteryDetail({
        type: this.allType.find((item) => this.radio === item.title).type,
        day: this.selctDate ? this.selctDate : '',
        page: this.pages
      })

      try {
        // 2、将数据追加到list 列表的最上面
        this.lotteryList.unshift(...data.lists)
        this.lotteryList = unique(this.lotteryList)
        // 3、下拉结束之后就将下拉状态关闭
        this.isrefreshLoding = false
        // 4、下拉成功时显示提示文字
        this.refreshSuccessText = this.$t('common.successfulRenewal')
      } catch (error) {
        // 如果下拉失败，则关闭下拉状态，显示下拉失败的消息
        this.isrefreshLoding = false
        this.refreshSuccessText = this.$t('common.refreshFailed')
      }
    }
  },
  computed: {
    isFull() {
      return this.lotteryList.length === this.total
    }
  }
}
</script>

<style lang="less" scoped>
.Single-container {
  height: 100%;

  .single-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  /deep/ .van-nav-bar__left {
    .iconfont {
      color: #fff;
    }
  }

  //   内容样式
  .singlepage {
    overflow: auto;

    .selectDown {
      position: fixed;
      top: 100px;
      left: 0;
      right: 0;
      z-index: 99;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;

      .selectData {
        height: 90px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;

        .squre {
          position: absolute;
          top: 45px;
          right: -45px;
          display: inline-block;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: #ccc;
        }

        .type {
          font-size: 30px;
          color: #555;
        }

        .selectType,
        .selectTime {
          width: 50%;
          line-height: 90px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .selectTime {
          margin-right: 30px;
        }

        .line {
          width: 1px;
          height: 90px;
          background-color: #ccc;
        }

        .text {
          font-size: 28px;
          top: 32px;
          color: #999;
          margin-right: 8px;
        }
      }

      .singleTitle {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 25px;
        color: #999;
        padding: 11px 30px;

        span {
          &:nth-child(1) {
            padding-left: 30px;
            margin-right: 15px;
          }

          &:nth-child(4) {
            padding-right: 65px;
          }
        }
      }
    }

    .singlelist {
      margin-top: 270px;
      height: calc(100% - 260px);
      position: fixed;
      width: 100%;
      top: 260;
      overflow: auto;

      .item-cell {
        box-sizing: border-box;
        // height: 120px;
        display: flex;
        align-items: center;
        padding: 11px 40px 11px 20px;
        border-bottom: 1px solid #eff3f4;

        /deep/ .van-cell {
          line-height: 16px;
        }

        /deep/ .van-cell__title {
          box-sizing: border-box;
          flex: 1.6;
          padding: 25px 0 18px 0;
          font-size: 24px;
        }

        /deep/ .van-cell__value {
          flex: 0.6;
          color: #13cc00;
          padding-top: 25px;
          font-size: 24px;
        }

        .orange {
          color: orange;
        }

        .title {
          display: flex;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;

          .winning {
            color: #ff0000;
            margin-left: 30px;
          }

          .greenWinning {
            color: #13cc00;
          }
        }

        .fastIcon {
          flex: 0.8;
          box-sizing: border-box;

          .fastTitle {
            height: 66px;
            text-align: center;
            font-size: 25px;

            .record-item {
              width: 55px;
              height: 55px;
              margin-right: 10px;
              vertical-align: middle;
            }
          }

          .fastLabel {
            font-size: 12px;
            color: #999;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
